.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 468px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray10);

  img {
    width: 44px;
    height: 44px;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: var(--c-gray100);
    margin-top: 24px;
    margin-bottom: 36px;
  }

  form {
    width: 100%;
  }
}

.button {
  margin-top: 24px;
}
